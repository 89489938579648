import React from "react";
import profileImage from "../assets/profileimage.avif"
import { Link } from "react-router-dom";
// import resumePDF from '../assets/ALEENA_ALBY.pdf'; 
import { FaInstagram, FaFacebook } from "react-icons/fa";



function Home() {
  return (
    <div className="min-h-screen text-gray-900 p-8">
      <header className="text-center mb-8">
      <img
          src={profileImage}
          alt="Profile"
          className="mx-auto w-32 h-32 rounded-full border border-gray-300 object-cover"
        />
        <div className="text-2xl font-bold">Aleena Alby</div>
        <div className="ext-lg text-gray-500 mt-1 t">Software Engineer</div>
        <div className="flex justify-center mt-2">
          <a href="https://github.com/AleenaAlby" className="text-gray-500 hover:text-black">
            GitHub
          </a>
        </div>
      </header>

      <main className="max-w-2xl mx-auto">
        <p className="text-sm leading-relaxed">Hi there!</p>
        <p className="text-sm leading-relaxed mt-4">
                I'm Aleena, a <span className="text-red-500">software engineer </span> 
                building innovative solutions in   
                <a 
                    href="https://www.quera.com/glossary/quantum-computing-as-a-service-qcaas" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-red-500 hover:text-red-700 underline underline-offset-2"
                > Quantum Computing as a Service (QCaaS)
                </a>, 
                    where I get to combine cutting-edge technology with my passion for problem-solving.
                    My work revolves around making quantum computing more accessible and 
                    impactful for real-world applications.
        </p>
        <p className="text-sm leading-relaxed mt-4">
        I graduated with a Master’s degree in Data Science and Computational Intelligence from Coventry University, England, in 2023.
        I conducted my dissertation under the supervision of<br></br>
        <a 
          href="https://www.linkedin.com/in/james-brusey-5543231/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-red-500 hover:text-red-700 underline underline-offset-2">  Dr. James Brusey
                </a>, focusing on the detection of Diabetic Retinopathy.
        </p>
        <p className="text-sm leading-relaxed mt-4">
        The project involved diagnosing Diabetic Retinopathy using fundus images from a Kaggle dataset, 
        employing advanced deep learning models such as ResNet50, DenseNet201, EfficientNetV2L, and VGG19. 
        It included preprocessing techniques like CLAHE and data balancing methods to enhance model performance.
        </p>
        {/* List of Writings */}
        <div className="mt-5 text-sm">
            <h2 className="text-gray-900">Life so far... ❤︎</h2>
            <div className="mt-1 space-y-2">
              <ul className="list-disc list-inside space-y-1 ml-1">
                <li className="pl-2">  
                  Born in <span className="font-bold"> 1997 </span>
                </li>
                <li className="pl-2">  
                Started <span className="font-bold">CS </span>in 2013
                </li>
                <li className="pl-2">  
                Completed <span className="font-bold">Bachelor's</span> in Computer Applications in 2018
                </li>
                <li className="pl-2">  
                Worked as <span className="font-bold">Software Engineer</span> at Emdeon Digital
                </li>
                <li className="pl-2">  
                Earned a <span className="font-bold">Master's</span> degree in Data Science and Computational Intelligence in 2023
                </li>
                <li className="pl-2">  
                Joined <span className="font-bold">OQC</span> as Junior Full Stack Engineer in 2023
                </li>
          </ul>
        </div>
            
        </div>
        <div className="mt-5 text-sm">
            <h3 className="text-gray-900"><span className="text-red-500">Projects</span></h3>
            <div className="mt-2">
                <Link to="/facebook_photo_counter" className=" hover:underline underline underline-offset-2">
                Facebook photo counter
                </Link>
                <div className="text-xs text-gray-500">Nov 1, 2024</div>
            </div>
            {/* <div className="mt-2">
              <a href="/rome-travel-guide" className="hover:underline underline-offset-2">
                Navigating Rome: A First-Timer's Guide to the Eternal City
              </a>
              <div className="text-sm text-gray-500">Dec 15, 2024</div>
              <p className="text-sm text-gray-600 mt-1">
                A comprehensive guide to exploring Rome, covering everything from transportation and tickets to language and safety tips.
              </p>
            </div> */}
            
        </div>

        <div className="mt-5 text-sm">
            <h3 className="text-gray-900"><span className="text-red-500">Blogs</span></h3>
            <div className="mt-2">
                <Link to="/blog/1" className=" hover:underline underline underline-offset-2">
                  Through the Streets of Rome
                </Link>
                <div className="text-xs text-gray-500">Jan 24, 2025</div>
            </div>  
            <div className="mt-2">
                <Link to="/blog/2" className=" hover:underline underline underline-offset-2">
                  The Art of Vincent Van Gogh
                </Link>
                <div className="text-xs text-gray-500">Feb 5, 2025</div>
            </div>  
            <div className="mt-2">
                <Link to="/" className=" hover:underline underline underline-offset-2">
                  Reverse Engineering (Work in progress)
                </Link>
                <div className="text-xs text-gray-500">Feb, 2025</div>
            </div>  
        </div>

        {/* Certifications Section */}
      <div className="mt-5 text-sm">
        <h3 className="text-gray-900"><span className="text-red-500">Certifications</span></h3>
        <div className="mt-2 space-y-2">
          <ul className="list-disc list-inside space-y-2 ml-2">
            <li className="pl-1">
              <a 
                href="https://learn.nvidia.com/certificates?id=YWOQFFraRNGT8Id53FsbbA"  
                className="hover:underline underline-offset-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fundamentals of Accelerated Computing with CUDA Python <span className="font-bold"> (Nvidia) </span>
              </a>
            </li>
            <li className="pl-1">
              <a 
                href="https://www.credly.com/badges/60fdebe3-e94b-4b89-9169-42b8c60b7876/linked_in_profile" 
                className="hover:underline underline-offset-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Certified: Azure Fundamentals
              </a>
            </li>
          </ul>
        </div>
      </div>

           {/* Contact Section */}
        <div className="mt-5 text-sm">
        <h3 className="text-gray-900"><span className="text-red-500">Contact</span></h3>
            <div className="mt-2">
                <p>
                Please use aleenaalby20@gmail[dot]com
                </p>
                <div className="flex space-x-4 mt-4">
              <a 
                href="https://www.instagram.com/aleena__alby" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-pink-600 transition-colors"
              >
                <FaInstagram size={24} />
              </a>
              <a 
                href="https://www.facebook.com/aleena.alby.9" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-blue-600 transition-colors"
              >
                <FaFacebook size={24} />
              </a>
            </div>
            </div>
            {/* <div className="mt-2">
                <a
        href={resumePDF}  
        download="ALEENA_ALBY_Resume.pdf"         
        target="_blank"                  
        rel="noopener noreferrer"       
      >
        Click here to download my resume
      </a>
            </div> */}
          
        </div>

        
        
        {/* <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">Here are some things I've written:</h2>
          <ul className="space-y-4">
            <li>
              <a href="#" className="text-red-500 hover:underline">
                The Hole in the Sky That We Actually Fixed
              </a>
              <div className="text-sm text-gray-500">May 17, 2024</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                For Sale: A Promise to Remove Invisible Gas
              </a>
              <div className="text-sm text-gray-500">May 31, 2023</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                Debugging Misadventures: Down the Rabbit Hole
              </a>
              <div className="text-sm text-gray-500">May 15, 2023</div>
            </li>
          </ul>
        </div> */}
      </main>
    </div>
  );
}

export default Home;
