import React from 'react';
import coverImage from "../assets/𝑉𝑖𝑛𝑐𝑒𝑛𝑡_𝑉𝑎𝑛_𝐺𝑜𝑔ℎ_cover.jpeg"; 
// import img1 from "../assets/vat1.jpg";
import img2 from "../assets/van4.jpg";
import img3 from "../assets/van3.jpg";
import img4 from "../assets/van1.jpg";
import img5 from "../assets/van2.jpg";

const RomeTravelBlog = () => {
  return (
    <div className="min-h-screen  text-gray-900 p-8">
      <header className="relative w-full h-64">
        <div 
          className="absolute inset-0 bg-cover bg-center "
          style={{
            backgroundImage: `url(${coverImage})`,
            filter: 'brightness(0.6)',
          }}
        ></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <div className="text-center text-white">
            <h1 className="text-xl font-bold">The Art of Seeing</h1>
            <p className="text-sm mt-2">Aleena | Feburary 4, 2025 | <a href="/" className="font-semibold underline">Home</a></p>
          </div>
        </div>
      </header>

      <section className="mt-8 max-w-4xl mx-auto">
        {/* <p className="text-xs leading-relaxed">
        I never consider myself a “writer”, let alone a blogger. 
        But lately, a quiet voice inside my heart nudging me to put my thoughts to words. 
        It’s strange, really. I’ve always admired people who could effortlessly express themselves, 
        whether through writing or speaking—but it’s never been “my thing.” Yet, here I am, giving it a try.
        </p> */}
        <p className="text-xs leading-relaxed mt-4">
        Italy awakened something in me. The colours, the textures, 
        the stories behind every brushstroke. It all left me in awe. When I returned home, I wanted more.
        So, I decided to make visiting the <span className="font-semibold italic">National Gallery in London</span> a highlight for January. 
        There’s something about London’s messy charm, its blend of grit and grandeur, that pulls me in. 
        It’s a city that feels alive, constantly moving, constantly evolving. I love its chaotic, fast-paced energy, 
        but there’s no denying it’s also undeniably expensive. That’s the part that usually makes me think twice before 
        stepping into the city. 
        </p>
        <p className="text-xs leading-relaxed mt-4">
        I wasn’t expecting such a long queue, especially in the rain. 
        Compared to Kochi, the UK rain is almost polite. 
        </p>
        <p className="text-xs leading-relaxed mt-4">
        It had already been a long day for me, and I hadn’t slept well the night before. 
        I was determined to make the most of my visit to the National Gallery. 
        I prepared a little note of what I wanted to see inside. My eyes were on <span className="font-semibold italic">Vincent van Gogh’s Sunflowers. </span>
        I headed straight for Room 31, where the Sunflowers was displayed. Part of me wanted to take my time, 
        to wander calmly through the gallery, soaking in every painting, every detail. But that day, 
        I was in a bit of a hurry. 
        </p>
        {/* <p className="text-xs leading-relaxed mt-4">
        As you enter the Basilica, you’ll be amazed by the beauty in every direction. One of the first things you'll see is <span className="italic">Michelangelo’s Pietà</span>, 
        a stunning sculpture of Mary holding the body of Christ. It’s placed in the first chapel on the right, and the detail makes it look so lifelike.
        You’ll also notice <span className="italic">St. Peter's Baldacchino</span>, a huge bronze canopy above the Papal Altar. 
        Made by Bernini, this 29-meter tall structure is a beautiful piece of art that connects heaven and earth. 
        Around the base of the dome’s drum, the solemn words of Matthew’s Gospel encircle 
        the space: <span className="font-semibold italic">“Tu es Petrus et super hanc petram aedificabo ecclesiam mean et tibi dabo claves regni caelorum” ("You are Peter, and on this rock I will build my church, and to you, 
          I will give the keys of the kingdom of heaven" - Mt 16:18).</span> 
        </p> */}
        <div className="mt-8 grid md:grid-cols-2 gap-4">
          <img 
            src={img4}
            loading="lazy"
            alt="On a train in Rome with luggage" 
            className="rounded-lg shadow-md w-full h-[400px] object-cover"
          />
          <img 
            src={img5}
            loading="lazy"
            alt="Vatican View" 
            className="rounded-lg shadow-md w-full h-[400px] object-cover"
          />
        </div>
        <div className="flex items-center mb-3 mt-3">
            <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 1. ​The Many Faces of Van Gogh’s Sunflowers</h3>
        </div>
        <p className="text-xs leading-relaxed mt-4">
        When I first saw Sunflowers in a Vase at the National Gallery in London, 
        I was struck by its beauty but also by a doubt. Something felt... off. 
        The signature, “Vincent,” wasn’t where I remembered it being in the images I had seen online. 
        For a moment, Is this a fake? 
        </p>
        <p className="text-xs leading-relaxed mt-1">
        I didn’t realize and what many people might not know. Van Gogh didn’t just paint one version of this iconic masterpiece. He painted eleven. These paintings are now scattered across the globe, from Tokyo to Amsterdam
        </p>
        <div className="flex items-center mb-3 mt-3">
            <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 2. Van Gogh didn’t start painting until he was in his late 20s</h3>
        </div>
        <p className="text-xs leading-relaxed mt-4">
        He worked as an art dealer, a teacher, and even a missionary. 
        It’s incredible to think that someone who created such timeless masterpieces had such a late start. 
        </p>
        
        <div className="flex items-center mb-3 mt-3">
            <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 3. More Than Just a Pretty Painting</h3>
        </div>
        <p className="text-xs leading-relaxed mt-4">
        Van Gogh’s sunflowers weren’t just decorative pieces, they held a meaning for him. 
        To Van Gogh, they symbolized gratitude and hope. In fact, he painted them to decorate 
        the room of his friend and fellow artist, Paul Gauguin, as a warm and heartfelt welcome.
        </p>
        <div className="mt-8 mb-8 grid md:grid-cols-2 gap-4">
          <img 
            src={img3}
            loading="lazy"
            alt="On a train in Rome with luggage" 
            className="rounded-lg shadow-md w-full h-[300px] object-cover"
          />
          <img 
            src={img2}
            loading="lazy"
            alt="Vatican View" 
            className="rounded-lg shadow-md w-full h-[300px] object-cover"
          />
        </div>
        <div className="flex items-center mb-3 mt-3">
            <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 4. The Woman Behind the Legend</h3>
        </div>
        <p className="text-xs leading-relaxed mt-4">
        Van Gogh’s immense popularity might never have happened without the efforts of a remarkable woman:Jo Bonger, 
        his sister-in-law. After Van Gogh’s death, it was Jo who preserved his legacy, tirelessly promoting 
        his work and lending his paintings to exhibitions in the 1890s. She was the one who ensured that 
        the world would come to know and appreciate Van Gogh’s genius.
        </p>
    
        <p className="text-xs leading-relaxed mt-4">
        <span className="font-semibold">Nb: </span>
        The opinions expressed in this blog are my own and are based on my personal reflections and research. 
        While I have made every effort to ensure the accuracy of the facts presented, 
        I encourage readers to explore further and form their own interpretations. 
        </p>
  

        {/* Closing Thoughts */}
        {/* <div className="mt-8">
            <p className="text-xs leading-relaxed ">
                Rome, the risk is much higher than I've seen in London.
            </p>
            <p className="text-xs leading-relaxed mt-4">
                All of that being said, I felt safe as long as you pay attention to your surroundings and things. 
                it’s a journey I’ll always remember. Of course, I would come back again—to see the things I haven’t seen yet!
            </p>
            <p className="text-xs leading-relaxed mt-4">
                For anyone who is on a similar journey, my advice would be to embrace the chaos and delve into the unknown. 
                I hope this helps people. 
            </p>
          <p className="mt-4 italic text-red-500 text-sm">Happy travels!</p>
        </div> */}
      </section>
    </div>
  );
};

export default RomeTravelBlog;